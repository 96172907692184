<template>
  <div class="files-tab-overview">
    <v-card class="mb-7">
      <v-card-title>Visa Rdr Files List</v-card-title>
      <div class="files-list">
        <v-list-item class="file-item" v-for="(file, key) in files" :key="key" two-line>
          <v-list-item-content>
            <v-list-item-title class="flex d-inline-flex align-center">
              <v-icon size="24" class="rounded-0">
                {{ resolveFileTypeVariant(file.type) }}
              </v-icon>
              <v-list-item-subtitle class="file-name">{{ file.name }}</v-list-item-subtitle>
            </v-list-item-title>
            <v-list-item-subtitle class="file-date">
              <div>Date: {{ file.lastModified | moment('YYYY-MM-DD') }}</div>
              <div>Size: {{ (file.size / (1024)).toFixed(2) }} KB</div>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <v-btn small icon class="me-n3 mt-n1">
              <v-menu bottom left>
                <template #activator="{ on, attrs }">
                  <v-btn class="files-list-item-show-more-actions" icon v-bind="attrs" v-on="on">
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item class='files-list-item-download' @click="downloadItem(file.name, file.type)">
                    <v-list-item-title>
                      <span>Download</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class='files-list-item-delete' @click="deleteFile(file.name)">
                    <v-list-item-title>
                      <span>Delete</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiLabelVariantOutline } from '@mdi/js';
import store from '@/store';
import Vue from 'vue';
import useChargebackFilesList from '@/views/chargebacks/chargeback-files/useChargebackFilesList';

export default {
  setup() {
    const { files, loading, resolveFileTypeVariant, fetchVisaRdrFiles } = useChargebackFilesList();

    fetchVisaRdrFiles();

    const deleteFile = fileName => {
      Vue.$confirm({
        message: `Are you sure you want to delete ${fileName}?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            store
              .dispatch('chargebackFile/deleteVisaRdrFile', fileName)
              .then(() => {
                files.value.splice(
                  files.value.findIndex(file => file.name === fileName),
                  1,
                );
              })
              .catch(() => {
                Vue.notify({
                  type: 'error',
                  title: 'Files',
                  text: `Cannot remove file with file name ${fileName}`,
                });
              });
          }
        },
      });
    };

    return {
      loading,
      files,
      resolveFileTypeVariant,
      deleteFile,
      icons: {
        mdiLabelVariantOutline,
        mdiDotsVertical,
      },
    };
  },
  methods: {
    downloadItem(fileName) {
      store.dispatch('chargebackFile/getVisaRdrFileUrl', fileName).then(fileUrl => {
        window.open(fileUrl, '_blank');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.files-list {
  padding: 20px;
  .file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
